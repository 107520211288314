<template>
  <v-footer id="core-footer" class="ma-4 rounded-lg" height="82" :color="$vuetify.theme.dark ? 'dark' : 'white'">
    <div class="footer-items d-none d-sm-flex">
      <span v-for="link in links" :key="link.name">
        <a :href="link.Link" class="tertiary--text footer-links">{{ link.name }}</a>
      </span>
    </div>
    <v-spacer />
    <span class="font-weight-light copyright">
      &copy;
      {{ new Date().getFullYear() }}
      <a :href="links[1].Link" target="_blank">Fabian</a> & <a :href="links[2].Link">Marco</a>, Mit
      <v-icon color="tertiary" size="17">mdi-heart</v-icon>
      aus Hamburg & Sexau
    </span>
    <span class="font-weight-light body-2 ml-2">v{{ appVersion }}</span>
  </v-footer>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AppFooter',
  setup(props, { root }) {
    return {
      appVersion: root.VUE_APP_VERSION,
      links: [
        { name: 'Home', Link: '/#' },
        { name: 'Minnovation', Link: 'https://minnovation.de' },
        { name: 'ME Networks', Link: 'https://me-networks.de' },
      ],
    }
  },
}) //
</script>
