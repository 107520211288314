<template>
  <v-main class="d-flex">
    <v-fade-transition mode="out-in">
      <router-view />
    </v-fade-transition>

    <div class="flex-grow-1" />

    <AppFooter v-if="$route.name !== 'login'" />
    <!-- <div id="core-view" :class="'page-' + this.$route.name" /> -->
  </v-main>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

import AppFooter from '@/components/core/AppFooter'

export default defineComponent({
  name: 'AppView',
  components: {
    AppFooter,
  },
})
</script>
